<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>来非凸，拥抱每一种可能！</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            探索与实践
            <div class="hr gradient-default"></div>
          </div>
          <div class="classification row gx-0 gy-4">
            <div
              class="left-container"
              style="margin-right: 0.75rem; width: calc(50% - 0.75rem)"
            >
              <div class="skew-element image left"></div>
            </div>
            <div
              class="right-container"
              style="margin-left: 0.75rem; width: calc(50% - 0.75rem)"
            >
              <div class="skew-element color right"></div>
              <div class="text">
                <div class="title position-relative lh-lg">
                  <div>这不仅仅是一份工作，更是一种体验</div>
                  <div class="mt-3">
                    如果你对数智交易行业 / Rust 语言充满兴趣，聪明、坚持、有创造力
                  </div>
                  <div class="mt-3">
                    我们将帮你做好定制化的培养计划和发展目标，助你更快地成长
                  </div>
                </div>
              </div>
            </div>
            <div
              class="right-container second-row d-block d-lg-none"
              style="margin-left: 0.75rem; width: calc(48% - 0.75rem)"
            >
              <div class="skew-element image right"></div>
            </div>
            <div
              class="left-container mobile"
              style="margin-right: 0.75rem; width: calc(52% - 0.75rem)"
            >
              <div class="skew-element color left"></div>
              <div class="text">
                <div class="title position-relative lh-lg">
                  <div style="padding-left: 1rem">你将收获:</div>
                  <ul>
                    <li>数位竞赛大佬亲身传授的开发经验</li>
                    <li>高效率无内卷的工作氛围</li>
                    <li>Facebook技术为王的全栈文化</li>
                    <li>投研一体的沉浸式金融知识理解</li>
                    <li>完善的职业规划体系建设</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="right-container d-none d-lg-block"
              style="margin-left: 0.75rem; width: calc(48% - 0.75rem)"
            >
              <div class="skew-element image right"></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            应届生培养计划
            <div class="hr gradient-default"></div>
          </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-5 gy-4">
            <div class="plan col">
              <img
                src="../../assets/img/join/internship-01.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">员工入职培训</div>
                <div class="fs-5 py-3 fw-light">
                  同期员工，入职指导，部门团建，融入非凸大家庭
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/internship-02.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">专业技能培训</div>
                <div class="fs-5 py-3 fw-light">
                  专业知识、实操演练、研究讨论......开发你的无限潜力
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/internship-03.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">一对一指导</div>
                <div class="fs-5 py-3 fw-light">
                  入职第一天，与导师配对，获得专业指导
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/internship-04.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">分享交流</div>
                <div class="fs-5 py-3 fw-light">
                  分享学习成果，畅言各种困惑，收获前辈经验
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            热招岗位
            <div class="hr gradient-default"></div>
          </div>

          <div v-for="job in jobs" :key="job.title">
            <job-card
              :campus="job.campus"
              :category="job.category"
              :icons="job.icons"
              :title="job.title"
              :location="job.location"
              :link="job.link"
              :description="job.description"
            >
            </job-card>
          </div>

          <div class="text-center my-5">
            <router-link
              to="/about/campus-poster"
              role="button"
              class="text-decoration-none btn btn-outline-danger"
              >了解更多</router-link
            >
          </div>
        </div>
      </section>
      <section class="bg-light" id="intern">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block d-md-none mb-5 ms-2" >
            关于实习生
            <div class="hr gradient-default"></div>
          </div>
          <div class="row mb-5 ms-2">
            <div
              class="
                col-md-4
                fw-bold
                fs-1
                d-flex
                flex-column
                justify-content-center
                d-none d-md-block
              "
            >
              <div class="fw-bold fs-1 text-center">实习生招募</div>
              <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
            </div>

            <div class="col-md-8 lh-lg">
              <div class="fs-3 pb-2 border-bottom border-dark border-2">
                诚邀在校优秀学子加入，共同探索科技的无限可能
              </div>
              <div class="row mt-4 fw-light gy-4 gx-5">
                <div class="fs-5 col-md-6">
                  在非凸，我们对你高标准、严要求，你可以快速融入研发一线，与业界精英并肩作战，迅速积累宝贵的工作经验，领悟技术精髓，享受工作带来的成就与乐趣。
                </div>
                <div class="fs-5 col-md-6">
                  在非凸，我们重视你的学习与成长，实习期间，你将获得专属导师悉心指导，全程答疑解惑，助力你在专业道路上加速前行。表现优异的实习生更有机会获得 Return Offer !
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
                  <section class="pt-5 life campusjob">
                    <div class="text-center lh-lg text-light my-5">
          <div class="fs-1 fw-bold">校园招聘活动</div>
          <div class>每年春季和秋季，我们都会加入全国各地知名高校的招聘活动，还有技术分享等宣讲活动</div>
          <router-link
            role="button"
            to="/about/livestream-poster"
            class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            >查看活动</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import JobCard from "../../components/JobCard.vue";
export default {
  name: "SocialJob",
  data() {
    return {
      jobs: [
        {
          title: "软件开发工程师",
          category: "研发",
          icons: "NEW",
          campus: true,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "负责公司核心数据生产系统的建设，包括爬虫、自动化等系统；根据业务需求，独立完成业务模块的设计与开发工作；参与系统的架构演进与技术迭代，不断提升系统的并发量与稳定性。",
          link: "/job/02",
        },
        {
          title: "算法工程师",
          category: "研发",
          icons: "NEW",
          campus: true,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "参与公司核心产品功能和架构开发；参与核心技术算法研究、实现和优化；参与前瞻性技术的跟踪和产品创新。",
          link: "/job/03",
        },
        {
          title: "策略研究员",
          category: "策略",
          icons: "NEW",
          campus: true,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "开发和设计中选股因子，构建选股模型；在多因子底仓上，开发设计股票底仓增强策略；其他以绝对收益为目标的投资策略，如风格轮动策略、股指期货策略、商品期货策略、套利策略等。",
          link: "/job/04",
        },
        {
          title: "机器学习研究员",
          category: "策略",
          icons: "NEW",
          campus: true,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "参与设计开拓性的智能算法应用于交易；参与构建科学、严谨的算法评测体系；紧跟领域前沿，推动基础研究；利用机器学习、深度学习和人工智能等方法，对历史数据进行研究、分析和统计，并从中寻找相关的趋势和规律。",
          link: "/job/05",
        },
      ],
    };
  },
  components: {
    JobCard,
  },
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.campusjob {
  background-size: cover;
      background-position: center;background-image:url('~@/assets/img/02.jpg')
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/campus_banner.jpg");
  background-position-x: 20%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.classification > div {
  min-height: 300px;
}
.classification > div {
  transform: skew(5deg);
  overflow: hidden;
}
.classification .text {
  position: relative;

  font-size: 1.2rem;
  left: 0;
  top: 0;
  width: 80%;
  margin: 0 auto;
  padding: 3rem 2.5rem;
  transform: skew(-5deg);
}
.classification .left.image {
  background-image: url("../../assets/img/join/campus-01.jpg");
  opacity: 0.8;
}
.classification .right.image {
  background-image: url("../../assets/img/join/campus-02.jpg");
  opacity: 0.8;
}
.classification {
  .left {
    width: 50%;
    transform-origin: bottom;
  }

  .right {
    width: 50%;
    transform-origin: top;
  }

  .color {
    background-color: $--ft-white;
    position: absolute;
  }

  .skew-element {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    transform: skewx(-5deg);
  }
}
.plan img {
  width: 8rem;
}
.plan {
  text-align: center;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .classification * {
    transform: skewX(0) !important;
  }
  .classification > div {
    width: 100% !important;
    margin: 0 !important;
  }
  .classification .second-row {
    margin-top: 3rem !important;
  }
  .classification .text {
    font-size: 1rem;
    width: 100%;
    margin: unset;
  }
  .right-container .text {
    top: 0%;
  }
  .plan {
    text-align: center;
  }
}
</style>
